import {createDailycalendarBlock} from "./modules/elementCreators/createDailycalendarBlock";

const $dailyCalendarCommentsTrigger = document.querySelector('.js-daily-calendar-comments-trigger');
const dailyCalendarCommentsType = document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=')
  return parts[0] === 'dailycalsettings2_date_type' ? decodeURIComponent(parts[1]) : r
}, '');
const dailyCalendarCommentsDate = document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=')
  return parts[0] === 'dailycalsettings2_date_str' ? decodeURIComponent(parts[1]) : r
}, '');
const dailyCalendarCommentsGender = document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=')
  return parts[0] === 'dailycalsettings2_gender_type' ? decodeURIComponent(parts[1]) : r
}, '');
const dailyCalendarCommentsIcon = document.cookie.split('; ').reduce((r, v) => {
  const parts = v.split('=')
  return parts[0] === 'dailycalsettings2_icon_type' ? decodeURIComponent(parts[1]) : r
}, '');
if ($dailyCalendarCommentsTrigger && (dailyCalendarCommentsType === '2' || dailyCalendarCommentsType === '3') && /^(\d+\-\d+\-\d+)$/.test(dailyCalendarCommentsDate)) {
  const today = new Date();
  const d = dailyCalendarCommentsDate.split('-');
  const baseDate = new Date(d[0], d[1] - 1, d[2], today.getHours(), today.getMinutes(), today.getSeconds());
  const days = (dailyCalendarCommentsType === '2' ? 280 : 0) + (Math.floor((today.getTime() - baseDate.getTime()) / 1000) / (60 * 60 * 24));
  if (days >= 0) {
    const callAPI = (url, beforeSend, callback, error) => {
      const httpRequest = new XMLHttpRequest();
      if (httpRequest) {
        httpRequest.addEventListener('load', (e) => {
          if (e.currentTarget.status === 200) {
            callback(e.currentTarget.response);
          } else {
            error();
          }
        });
        httpRequest.addEventListener('error', error); // 通信エラー
        httpRequest.addEventListener('abort', error); // 処理中止
        // 実行
        if (httpRequest.readyState === XMLHttpRequest.UNSENT || httpRequest.readyState === XMLHttpRequest.DONE) {
          beforeSend();
          httpRequest.open('GET', url);
          httpRequest.responseType = 'json';
          httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
          httpRequest.send();
        }
      }
    };
    const url = $dailyCalendarCommentsTrigger.dataset.url + `/api/v2/daily-calendar-comments/${Math.floor(days)}.json?type=${encodeURIComponent(dailyCalendarCommentsType)}&date=${encodeURIComponent(dailyCalendarCommentsDate)}&gender=${encodeURIComponent(dailyCalendarCommentsGender)}&icon=${encodeURIComponent(dailyCalendarCommentsIcon)}`;
    const beforeSend = () => {
      Array.prototype.slice.call(document.querySelectorAll('.js-daily-calendar-comments-block')).forEach($viewer => {
        $viewer.style.display = 'none';
      });
    };
    const callback = (response) => {
      response && response.data && Array.prototype.slice.call(document.querySelectorAll('.js-daily-calendar-comments-block')).forEach($viewer => {
        const $flexDisplay = $viewer.parentElement;
        $viewer.remove();
        $flexDisplay.appendChild(createDailycalendarBlock(response.data, today));
      });
    };
    const error = () => {
      Array.prototype.slice.call(document.querySelectorAll('.js-daily-calendar-comments-block')).forEach($viewer => {
        $viewer.style.display = null;
      });
    };
    if ($dailyCalendarCommentsTrigger.classList.contains('is-active')) {
      callAPI(url, beforeSend, callback, error);
    } else {
      $dailyCalendarCommentsTrigger.addEventListener('click', () => {
        callAPI(url, beforeSend, callback, error);
      }, { once: true });
    }
  }
}
