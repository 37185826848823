export { createDailycalendarBlock };

const monthNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const weekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function formatDate(date) {
  return date.getFullYear() + '.' + monthNames[date.getMonth()] + '.' + date.getDate() + ' ' + weekNames[date.getDay()];
}

function createDailycalendarBlock(data, today) {
  const $div = document.createElement('div');
  $div.classList.add('l-status', 'box-border', 'is-white');
  {
    $div.appendChild(data.hasOwnProperty('age_year') ? createBabyStatusBlock(data) : createPregnancyStatusBlock(data));
  }
  {
    const $row = document.createElement('div');
    $row.classList.add('row');
    {
      const $col = document.createElement('div');
      $col.classList.add('col-xs-12', 'mb10');
      {
        const $col_row = document.createElement('div');
        $col_row.classList.add('row', 'middle-xs');
        {
          const $photo = document.createElement('div');
          $photo.classList.add('col-xs-2');
          const $img = document.createElement('img');
          $img.classList.add('is-rounded', 'is-senmonka');
          $img.src = data.p_image;
          $img.alt = '日めくりカレンダー';
          $photo.appendChild($img);
          $col_row.appendChild($photo);
        }
        {
          const $title = document.createElement('div');
          $title.classList.add('col-xs');
          const $text = document.createElement('div');
          $text.classList.add('fw-bold');
          $text.textContent = data.title;
          $title.appendChild($text);
          $col_row.appendChild($title);
        }
        $col.appendChild($col_row);
      }
      $row.appendChild($col);
    }
    {
      const $col = document.createElement('div');
      $col.classList.add('col-xs-12');
      $col.textContent = data.description;
      $row.appendChild($col);
    }
    $div.appendChild($row);
  }
  const $wrap = document.createElement('div');
  $wrap.style.margin = 'auto';
  {
    const $date = document.createElement('div');
    $date.classList.add('ft-mon', 'fw-bold', 'text-center');
    $date.textContent = formatDate(today);
    $wrap.appendChild($date);
  }
  $wrap.appendChild($div);
  return $wrap;
}

function createPregnancyStatusBlock(data) {
  const $row = document.createElement('div');
  $row.classList.add('row', 'ft-mon', 'mb10');
  {
    const $status = document.createElement('div');
    $status.classList.add('l-status-col-l', 'col-xs-7', 'text-center');
    {
      const $p = document.createElement('p');
      {
        const $span = document.createElement('span');
        $span.textContent = '妊娠 ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = Math.floor(data.days / 7);
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '週と ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.days % 7;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $status.appendChild($p);
    }
    $row.appendChild($status);
    const $days = document.createElement('div');
    $days.classList.add('col-xs-5', 'text-center');
    {
      const $div = document.createElement('div');
      $div.classList.add('label-round', 'is-pink');
      if (data.days > 280) {
        $div.textContent = '出産予定日から';
      } else {
        $div.textContent = '出産予定日まで';
      }
      $days.appendChild($div);
    }
    {
      const $p = document.createElement('p');
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        if (data.days > 280) {
          $span.textContent = `${data.days - 280}`;
        } else {
          $span.textContent = `${280 - data.days}`;
        }
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $days.appendChild($p);
    }
    $row.appendChild($days);
  }
  return $row;
}

function createBabyStatusBlock(data) {
  const $row = document.createElement('div');
  $row.classList.add('row', 'ft-mon', 'mb10');
  {
    const $status = document.createElement('div');
    $status.classList.add('l-status-col-l', 'col-xs-7', 'text-center');
    {
      const $p = document.createElement('p');
      if (data.age_year > 0) {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.age_year;
        $p.appendChild($span);
      }
      if (data.age_year > 0) {
        const $span = document.createElement('span');
        $span.textContent = '才 ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.age_month;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = 'カ月と ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.age_day;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $status.appendChild($p);
    }
    $row.appendChild($status);
    const $days = document.createElement('div');
    $days.classList.add('col-xs-5', 'text-center');
    {
      const $div = document.createElement('div');
      $div.classList.add('label-round', 'is-pink');
      $div.textContent = 'お誕生日から';
      $days.appendChild($div);
    }
    {
      const $p = document.createElement('p');
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.days;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $days.appendChild($p);
    }
    $row.appendChild($days);
  }
  return $row;
}
